* {
    margin: 0;
    padding: 0;
    border: none;
    scroll-behavior: smooth;
}

@font-face {
    font-family: Averta;
    src: url(../font/Averta-Bold.30074e57.woff);
}

@font-face {
    font-family: Avertaa;
    src: url(../font/Averta-Regular.d88182eb.woff);
}

@font-face {
    font-family: Avertaaa;
    src: url(../font/Averta-Semibold.58d8ee25.woff);
}

/* .App span {
    position: fixed;
    margin-top: 15%;
    margin-left: 50%;
    transform: translate(-50%, -50%);
} */

.top-to-btm {
    position: relative;
}

.icon-position {
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
}

.icon-style {
    background-color: #2c83e6;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
}

.icon-style:hover {
    .container_user {
        width: 1200px;
        margin: 0 auto;
    }

    animation: none;
    background: #fff;
    color: #551B54;
    border: 2px solid #551B54;
}

@keyframes movebtn {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(20px);
    }

    50% {
        transform: translateY(0px);
    }

    75% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}

header {
    display: block;
}

body {
    background-color: #f8fafd;
    font-family: Avertaa;
    /* -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji; */
}


a {
    font-family: Avertaaa;
    text-decoration: none;
    color: #000;
}

li {
    list-style: none;
}

i {
    font-style: normal;
}

p:hover {
    cursor: default;
}

.description {
    font-family: Avertaa;
    font-size: .875rem;
    color: #828282;
    font-weight: 400;
}

.name_text {
    font-family: Avertaa;
    font-size: .875rem;
    font-weight: 700;
}

.box_shawdown {
    background-color: #fff;
    width: 320px;
    height: 75px;
    gap: 20px;
    border-radius: 10px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15) !important;
    margin-bottom: 5px;
    transition: all .25s ease-in-out;
    cursor: pointer;
}

/* Glow */
.glow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
}

.glow:hover,
.glow:focus,
.glow:active {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

/* Grow Shadow */
.grow-shadow {
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
}

.grow-shadow:hover,
.grow-shadow:focus,
.grow-shadow:active {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

/* Bounce To Top */
.bounce {
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
}

.bounce:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2c83e6;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    border-radius: 10px;
    cursor: pointer;
}

.bounce:hover a,
.bounce:focus a,
.bounce:active {
    color: #f8fafd;
}

.bounce:hover:before,
.bounce:focus:before,
.bounce:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

@-webkit-keyframes bob {
    0% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }

    50% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px);
    }

    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
}

@keyframes bob {
    0% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }

    50% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px);
    }

    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
}

@-webkit-keyframes bob-float {
    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
}

@keyframes bob-float {
    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
}

.procedure-container_contents-title:hover p {
    cursor: pointer;
}

.bob {
    /* display: inline-block; */
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.bob:hover,
.bob:focus,
.bob:active {
    -webkit-animation-name: bob-float, bob;
    animation-name: bob-float, bob;
    -webkit-animation-duration: .3s, 1.5s;
    animation-duration: .3s, 1.5s;
    -webkit-animation-delay: 0s, .3s;
    animation-delay: 0s, .3s;
    -webkit-animation-timing-function: ease-out, ease-in-out;
    animation-timing-function: ease-out, ease-in-out;
    -webkit-animation-iteration-count: 1, infinite;
    animation-iteration-count: 1, infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-direction: normal, alternate;
    animation-direction: normal, alternate;
    cursor: pointer;
}

.bounce-in {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
}

.bounce-in:hover,
.bounce-in:focus,
.bounce-in:active {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
    transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
    cursor: pointer;
    border-radius: 10px;
}

.container_user {
    max-width: 1000px;
    margin: 0 auto;
}