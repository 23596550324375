.select__hopital {
    max-width: 760px;
    margin: 0 auto;
    min-height: 500px;
}

.select__hopital-search {
    display: flex;
    gap: 50px;
    padding: 20px 0;
}

.select__hopital-search input {
    width: 420px;
    height: 40px;
    border: 1px solid #1da1f2;
    border-radius: 10px;
    padding: 0 1rem;
}

.hopotal__box-img img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.select__hopital-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.hopotal__box {
    display: flex;
    gap: 20px;
    align-items: center;
}

.listItem {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 100px !important;
}

.hopotal__box-content-name {
    padding-bottom: 5px;
}

.listItem:hover .hopotal__box-content-name {
    color: #6495ed;
    transition: all 0.3s ease-in-out;
}

.select__hopital {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.date_time_kham {
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    margin-top: 100px;
}

.bacsi_body_div {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    min-width: 660px;
    max-width: 660px;
}

.basi_body_time-btn {
    cursor: pointer;
    background-color: #3de68c;
    color: #fff;
    padding: 10px;
    border-radius: 20px;
    font-weight: 600;
    margin-top: 10px;
    min-width: 150px;
}

.no_date {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.no_date p {
    margin-top: 50px;
    font-weight: 700;
    font-size: 20px;
    color: #eb3131;
}

.no_date img {
    width: 180px;
    height: 180px;
}

.basi_body_time-btn:hover {
    background-color: #1da1f2;
    transition: 0.5s ease-in-out;
}

.box_chon_lcih_kham {
    display: flex;
}

@media screen and (max-width: 960px) {
    .format__header {
        background-color: #1da1f2 !important;
        padding-left: 1% !important;
    }

    .select__hopital {
        max-width: 360px;
    }

    .select__hopital-list {
        grid-template-columns: 1fr;
    }
}