.null_data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
}

.null_data h2 {
    color: rgb(177, 177, 177);
}

.line_phieu_kham {
    border-bottom: 1px dashed #003553;
    padding-bottom: 12px;
    margin-bottom: 16px;
    max-width: 450px;
}

.btn_phieu_kham {
    margin-top: -50px;
    cursor: pointer;
    background-color: #3de68c;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    font-weight: 600;
}

/* .btn_ct {
    cursor: pointer;
    background-color: #3de68c;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    font-weight: 600;
    float: inline-end;
    margin-top: -0px;
} */
.btn_ct {
    float: right;
    margin-top: -25px;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #b3b3b3;
    color: #fff;
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
}