.container_addBenhVien{
    background-image: url('../../img/phongkham/pkpm_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height:calc(100vh - 82.54px);
    
}
.content_addBenhVien{
    background: rgba(255, 255, 255, 0.5);
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(255, 252, 252, 0.1); /* Bóng đổ với độ mờ và màu sắc xác định */
    margin: 15px 0;
    
}
.title_insertHopital{
    text-align: center;
    font-size: 20px;
    font-weight: bold;;
}
.lable_InsertHopital{
    margin-bottom: 10px;
    font-weight: bold;
    color:#4a4a4a
}
.form_InsertHopital{
    margin: 20px 0;
    width: 70%;
}
.inout_InsertHopital{
    background-color:rgba(255, 255, 255, 0.5);
    border:none
}
.customUpload .ant-upload.ant-upload-select{
    width: 200px !important;
    height: 200px !important;
}
.customUploadModal .ant-upload.ant-upload-select{
    width: 150px !important;
    height: 150px !important;
}   
.customAddNews .ant-upload.ant-upload-select{
    width:300px !important;
    height: 200px !important;
    border-radius: 50% !important;

}  
.customUploadModal .ant-upload.ant-upload-select.ant-upload img,.customUpload .ant-upload.ant-upload-select.ant-upload img{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.content_ListHospital{
    margin-top:50px;
}

.rc-md-navigation{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
