.main_profile {
    padding: 30px 30px;
}

.infor_user {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.infor_user p {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 10px;
    color: rgb(0, 53, 83);
}

.infor_user p span {
    font-weight: 600;
}

.infor_user p span:first-child {
    color: #888888;
    font-size: 18px;
}

.box_profile {
    /* max-width: 1200px; */
    border-radius: 10px;
    border: 1px solid #eaeaea;
    box-shadow: 4px 8px 30px 0 rgba(177, 196, 218, .35);
    margin: 0 auto;
    margin-top: 20px;
    padding-top: 20px;
}

.box_profile_avatar img {
    width: 180px !important;
    height: 180px !important;
    border-radius: 50%;
    border: 3px dashed #00b5f1;
    box-shadow: 8px 8px 30px 0 rgba(177, 196, 218, .35);
    object-fit: cover;
}

.btn_edit {
    float: right;
    line-height: 60px;
    margin-right: 20px;
    color: #00b5f1;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
}

.box_profile_btn {
    width: 100%;
    height: 60px;
    background: #f5f5f5;
    margin-top: 30px;
}

.inforMed {
    margin: 0 auto;
}

.inforMed img {
    width: 80px;
}

.input_update_user {
    background-color: #f5f5f5;
    border: 1px solid #eaeaea;
    box-shadow: 4px 8px 30px 0 rgba(177, 196, 218, .35);
}