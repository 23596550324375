.main_hd {
    height: 5600px;
    position: relative;
    background-color: #fff;
}

.container_hd {
    position: absolute;
    height: 1440px;
}

.content_hd {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding-bottom: 60px;
    width: 100%;
}

.header_hd {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;



}

.header_hd>p {
    font-size: 35px;
    font-weight: 550;
    color: #fff;

}

.Buoc {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    margin-top: 50px;
    width: 100%;
    justify-content: center;
}

.left1 {

    width: 10%;
    color: rgb(51, 125, 231);
    font-size: 18px;
    font-weight: 700;
}

.right1 {
    width: 70%;
    /* width:823px; */
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 0 0 30px 50px;
    line-height: 30px;

}

.content_hd_item {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.one {
    color: rgb(51, 125, 231);
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 15px;

}

.methor {
    font-size: 16px;
    font-weight: bold;
}

.setting {
    margin: 16px 0;
}

.right1>ul ol {
    list-style: disc;
    line-height: 30px;
}

.icon_qr {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 0 50px #ccc;
    margin: 20px 0;

}

.login_dangkysdt {
    text-decoration: underline;
}

.Nhapthongtin {
    padding: 10px 20px 10px 20px;

}

.login_tt {
    list-style-type: circle;
    font-size: 16px;
    padding: 5px 0;
    line-height: 30px;
}

.icon_buoc2 {
    padding: 40px;
}

.select {
    font-weight: bold;
    padding-top: 5px;
}

.two {
    padding-top: 15px;
}

@media only screen and (max-width:46.1875em) {
    .content_hd {
        flex-direction: column;
    }

    .Buoc {

        flex-direction: column;
        width: 90%;
        padding-left: 40px;


    }

    .content_hd_item {
        margin: 0 auto;
        width: 95%;
    }

    .left1 {
        padding-bottom: 10px;
        font-size: 16px;
        width: auto;
    }

    .one {
        font-size: 16px;
    }

    .right1 {

        padding: 0;
        width: 90%;
        border: none;
    }


    .icon_qr {
        width: auto;
        height: auto;
    }

    .icon_qr>img {
        width: 90%;
        height: 80%;
    }

}