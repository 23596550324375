.box_headerDashboard{
    min-height: 150px;
    border-radius:15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.box_headerDashboard:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.box_headerDashboard_item{
    padding:20px;
    /* height:100%; */
    box-shadow: 0px 0px 0px #fff;
}
.title_headerDashBoard{
    font-weight: bold;
    font-size: 16.5px;
    margin-bottom:30px;
    color: #fff;
}
.content_headerDashboard{
    font-size: 26px;
    font-weight: 600;
    color: #fff
}
.title_dashboard{
    text-align: center;
    margin-bottom: 30px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 22px;
}
.chart_Dashboard{
    width: 100%;
    margin-top: 40px;
}
.content_chart{
    margin-top: 30px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    padding: 30px 0;
}