.container_headerAdmin{
    width: '100%';
    padding: 20px 50px;
    border-bottom: 1px solid #ccc;
    position: sticky;
    background-color: #fff;
    top: 0;
    z-index: 2;
}
.box_logoAdmin{
    width: 320px;
}
.toolTip_HeaderBell{
  z-index: 10;
}
.icon_bellFocus{
    position: absolute;
    top: 0;
    right:0;
    height:6px;
    width:6px;
    border-radius:50%;
    background-color:red;
}
.box_infAdminUser:hover{
   color:#008CFF;
   font-weight: 500;
}
.box_infAdminUser{
    color: gray;
}
#box_BellHeader{
    background-color:#fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border: 0.5px solid #ccc;
    padding:10px;
    /* border-radius:20px; */
    border-top-left-radius: 10px;
    border-bottom-right-radius:10px;
    border-bottom-left-radius: 10px;
}
.content_boxHeaderBell::-webkit-scrollbar {
    display: none;
  }
  .content_boxHeaderBell{
    overflow-y:auto;
    height:300px;
  }
  .react-tooltip-arrow{
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
  .item_HeaderBell{
    width: 300px;
    padding: 15px 10px;
    border-radius:10px ;
  }
  .item_HeaderBell:hover{
    background-color:#f1f1f1;
    cursor: pointer;
    
  }